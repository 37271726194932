@import "src/styles/colors";
@import "src/styles/text";

.profile-file-upload {
  margin-bottom: 64px;

  &-title {
    @include text-small-subtitle;
    margin-bottom: 24px;

    &-optional {
      @include text-small;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &-container {
    padding: 40px 52px;
    min-height: 85px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23AAAAAA' stroke-width='4' stroke-dasharray='14, 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 30px;
    display: flex;
    align-content: center;

    &-image {
      align-content: center;
      margin-right: 24px;
      cursor: pointer;

      input {
        display: none;
      }
    }

    &-description {
      align-content: center;

      &-title {
        margin-bottom: 8px;
        @include text-subtitle;
      }

      &-text {
        @include text-body;
      }
    }

    &-divider {
      align-content: center;
      margin-left: auto;
      margin-right: 110px;
      opacity: 0.5;

      &-line {
        width: 1px;
        height: 24px;
        background: $color-rich-black;
        margin-bottom: 4px;
        margin-top: 4px;
        border-radius: 1px;
        margin-left: 9px;
      }

      &-text {
        @include text-small;
        text-transform: uppercase;
        line-height: 22px;
      }
    }

    &-browse {
      align-content: center;
      //margin-left: auto;
      margin-right: 44px;
      @include text-small;
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;

      &-link {
        @include text-small-subtitle;
        font-weight: initial;
        cursor: pointer;
        color: $color-dark-yellow;
        text-decoration: underline;
      }
    }
  }
}