.evidence-component {
  margin-bottom: 24px;
  position: relative;

  &-table-creating {
    padding-left: 50px;
  }

  .checkbox {
    display: inline-flex;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}