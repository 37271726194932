@import "src/styles/colors";
@import "src/styles/text";


.file-info {
  border-radius: 30px;
  border: 1px solid rgba($color-rich-black, 0.5);
  padding: 30px 32px;
  margin-bottom: 16px;

  &-header {
    &-icon {
      margin-right: 16px;
      vertical-align: sub;
    }

    &-name {
      display: inline-block;
      @include text-subtitle;
    }

    &-info {
      display: inline-block;


      &-title {
        @include text-subtitle;
        margin-bottom: 8px;
      }

      &-text {
        @include text-body;
      }
    }

    &-trash {
      display: inline-block;
      float: right;
      height: 59px;

      img {
        margin-top: 19px;
      }
    }

    &-loading {
      display: inline-block;
      float: right;

      img {
        margin-right: 5px;
        vertical-align: sub;
      }

      &-text {
        display: inline-block;
        @include text-small-subtitle;
        font-size: 14px;
      }
    }
  }

  &-closed {
    cursor: pointer;

    .file-info-header-icon {
      width: 27px;
      vertical-align: super;
      margin-right: 28px;
    }
  }

  &-progress {
    height: 6px;
    width: 100%;
    background: #FFD500;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 22px;
  }

  &-large-input {
    input {
      //min-height: 118px;
    }
  }

  &-footer {
    text-align: right;

    .btn {
      margin-left: 16px;
    }
  }
}